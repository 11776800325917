import AOS from 'aos';
import cookieConsent from './cookie_consent'

// Stimulus
import 'controllers/landing'
import Rails from '@rails/ujs'

require("turbolinks").start()

Rails.start()

global.$ = $

document.addEventListener('turbolinks:load', () => {
  AOS.init({
    disable: 'mobile',
    duration: 600,
    once: true,
    disableMutationObserver: true,
  })

  cookieConsent()
})

document.addEventListener('turbolinks:before-cache', () => {
  document.querySelectorAll('.aos-init').forEach((el) => el.classList.remove('aos-init'))
  document.querySelectorAll('.aos-animate').forEach((el) => el.classList.remove('aos-animate'))
})
